body {
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 1rem;
	line-height: 1.5;
}

h1 {
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 38px;
	line-height: 44px;
	font-weight: 500;
}

h2 {
	font-size: 1.5rem;
	line-height: 1.4;
	font-weight: 500;
}

h3 {
	font-size: 1.1rem;
	font-weight: 500;
}

h4 {
	font-size: 1rem;
	font-weight: 500;
}

p {
	margin-bottom: 10px;
}

a {
	color: #826338;
	text-decoration: underline;
}

a:hover {
	color: #2b2c18;
}

img {
	display: inline-block;
	max-width: 100%;
}

.section-slide {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100vh;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.left-block {
	position: fixed;
	left: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40%;
	height: 100vh;
	margin-top: 0px;
	padding: 220px 40px 40px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: transparent;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.heading-titles {
	margin-top: 0px;
	margin-bottom: 0.5rem;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 500;
	letter-spacing: 0.5px;
	text-transform: capitalize;
	text-shadow: 1px 1px 6px #000;
}

.heading-titles.white {
	max-width: 300px;
	color: #fff;
	font-size: 2rem;
	line-height: 2.25rem;
	text-transform: capitalize;
}

.heading-titles.centered {
	margin-bottom: 1.5rem;
	color: #f9f5f2;
	text-align: center;
	text-transform: none;
	text-shadow: none;
}

.inner-content {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: auto;
	padding-right: 0px;
	padding-left: 0px;
}

.inner-content.category {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.paragraph {
	width: 90%;
	max-width: 600px;
	margin-bottom: 20px;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 300;
	letter-spacing: 0px;
}

.home-content-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.products-wrapper {
	display: block;
	width: 60%;
	height: auto;
	margin-top: 0px;
	padding: 40px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px none #000;
	background-color: #826338;
}

.product-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: 300px;
	margin-right: 0px;
	margin-left: 0px;
	padding: 10px 20px 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-style: none;
	border-width: 0.05px;
	border-color: rgba(63, 62, 62, 0.15);
	background-color: hsla(0, 0%, 98%, 0.2);
	-webkit-transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	color: #fff;
	text-decoration: none;
	cursor: pointer;
}

.product-link-block:hover {
	border: 1px none #000;
}

.product-link-block._2 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.product-link-block._3 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.product-link-block._4 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	color: #f7f7f7;
}

.product-link-block._5 {
	background-position: 0px 0px, 0% 50%;
	background-size: auto, cover;
}

.product-link-block._6 {
	background-position: 0px 0px, 100% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.product-link-block.columns-1 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.product-title {
	display: inline-block;
	width: 100%;
	margin-bottom: 10px;
	padding-top: 0px;
	padding-bottom: 0.125rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	border-style: none none solid;
	border-width: 1px 1px 1.5px;
	border-color: #000 #000 #f9f5f2;
	-webkit-transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Poppins, sans-serif;
	color: #f9f5f2;
	font-size: 1rem;
	line-height: 1;
	font-weight: 500;
	text-align: left;
	letter-spacing: 0.5px;
	font-weight: bold;
	text-transform: capitalize;
}

.product-title:hover {
	border-bottom-color: transparent;
}

.product-description {
	margin-top: 0px;
	margin-bottom: 7px;
	padding-bottom: 0px;
	font-family: Poppins, sans-serif;
	color: #f9f5f2;
	font-size: 0.75rem;
	line-height: 0.8rem;
	font-weight: 400;
	text-align: left;
	letter-spacing: 0.5px;
	text-decoration: none;
	text-transform: capitalize;
}

.content-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60%;
	height: 250px;
	padding-right: 60px;
	padding-left: 60px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.content-block.call-out {
	height: 300px;
	padding-right: 40px;
	padding-bottom: 40px;
	padding-left: 40px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
	background-position: 0px 0px, 50% 30%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	-webkit-transition: all 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	cursor: pointer;
}

.content-block.call-out:hover {
	background-color: transparent;
}

.main-button {
	display: inline-block;
	margin-right: 0px;
	margin-left: 20px;
	padding-right: 22px;
	padding-left: 22px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: solid;
	border-width: 1px;
	border-color: #f7f7f7;
	border-radius: 0px;
	background-color: #f7f7f7;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 0.65rem;
	line-height: 1rem;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.main-button:hover {
	border-style: solid;
	border-width: 1px;
	border-color: #040404;
	background-color: #fff;
	color: #040404;
}

.main-button._2 {
	margin-right: 0px;
	margin-left: 0px;
	padding-right: 22px;
	padding-left: 22px;
	border-style: solid;
	border-width: 1px;
	border-color: #040404;
	border-radius: 3px;
	background-color: #fff;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 0.8rem;
	letter-spacing: 0.5px;
	text-transform: capitalize;
}

.main-button._2:hover {
	background-color: #040404;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #fff;
}

.main-button._2.content {
	margin-right: 0px;
	border-radius: 3px;
	font-size: 0.8rem;
	font-weight: 500;
	letter-spacing: 0.5px;
	text-transform: capitalize;
}

.button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-bottom: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.button-wrap:hover {
	background-color: transparent;
}

.call-out-image-block-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60%;
	height: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
}

.call-out-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: 400px;
	padding-right: 40px;
	padding-bottom: 40px;
	padding-left: 40px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	cursor: pointer;
}

.call-out-image:hover {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.call-out-image._2 {
	background-color: transparent;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.call-out-image._1 {
	border-right: 1px solid #2b2c18;
	background-color: #ede9e2;
	background-position: 0px 0px, 50% 30%;
	background-size: auto, cover;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
}

.footer-sign-off {
	display: block;
	padding: 15px 40px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-top: 1px solid rgba(249, 245, 242, 0.25);
	background-color: transparent;
}

.social-media-link-block-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30px;
	height: 30px;
	margin-right: 0.4rem;
	margin-left: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: #f9f5f2;
	-webkit-transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	text-align: center;
}

.social-media-link-block-2:hover {
	border-color: #f9f5f2;
	border-radius: 0px;
	background-color: #2b2c18;
}

.social-media-link-block-2.fb {
	background-image: url('/20181016082423/assets/images/638e5992917f31c8c9f7d95f_facebook-icon-01-1.svg');
	background-position: 50% 50%;
	background-size: 23px 23px;
	background-repeat: no-repeat;
}

.social-media-link-block-2.fb:hover {
	background-image: url('/20181016082423/assets/images/638e5992917f31c8c9f7d95f_facebook-icon-01.svg');
}

.footer-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 60px 40px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-top: 1px solid rgba(4, 4, 4, 0.05);
	background-color: transparent;
}

.footer-column-one {
	display: block;
	width: 15%;
	padding-right: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.footer-column-one.support {
	width: 20%;
	margin-top: 0px;
	padding-right: 0px;
}

.footer-column-two {
	width: 20%;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-right: 0px;
	padding-left: 0px;
}

.footer {
	width: 60%;
	margin-left: auto;
	padding-right: 0%;
	padding-left: 0%;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	background-color: #826338;
	color: #f9f5f2;
}

.footer.is--full {
	width: 100%;
}

.footer-left-content {
	display: block;
	width: 40%;
	height: auto;
	margin-right: 0rem;
	padding-right: 0px;
}

.social-media-wrapper {
	position: relative;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	margin-top: 0rem;
	margin-bottom: 0rem;
	margin-left: -5px;
	padding-top: 0px;
	float: left;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.footer-column-heading {
	display: none;
	margin-top: 0px;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 0.85rem;
	line-height: 1rem;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.link-block-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-decoration: none;
}

.image-2 {
	margin-bottom: 20px;
	margin-left: 0px;
}

.nav-bar {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	display: block;
	width: auto;
	height: auto;
	max-width: none;
	margin-top: 0px;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 3px none #aa5e40;
	border-bottom: 1px solid rgba(4, 4, 4, 0.05);
	background-color: #fff;
}

.nav-bar:hover {
	background-color: #fff;
}

.slider {
	width: 60%;
	height: 100%;
}

.slide {
	background-color: #f5f5f5;
	background-position: 50% 40%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slide-nav {
	font-size: 0.65rem;
}

.left-arrow {
	left: 0%;
	top: auto;
	right: auto;
	bottom: 0%;
	height: 83vh;
	color: #3f3e3e;
	font-size: 2rem;
}

.icon-4 {
	margin-top: auto;
	margin-bottom: auto;
	opacity: 1;
	font-size: 1.75rem;
}

.icon-5 {
	margin-top: auto;
	margin-bottom: auto;
	font-size: 1.75rem;
}

.slide-2 {
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/5-tall.jpg');
	background-position: 50% 100%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slide-3 {
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/1-6.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.form-block-2 {
	width: 100%;
	margin-bottom: 0px;
	margin-left: 0px;
	padding-left: 0px;
	border-style: none;
	border-width: 0.1px;
	border-color: rgba(51, 51, 51, 0.25);
	border-radius: 5px;
	color: #040404;
}

.form-3 {
	border-style: none none solid;
	border-width: 1px 1px 0.15px;
	border-color: #000 #000 rgba(4, 4, 4, 0.05);
	color: #040404;
}

.text-field-3 {
	margin-bottom: 0px;
	padding-left: 10px;
	border: 1px none #000;
	background-color: transparent;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 0.75rem;
	font-weight: 400;
}

.div-block-131 {
	width: 30px;
	height: 30px;
	background-image: url('/20181016082423/assets/images/exit-icon-01.svg');
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
	color: #040404;
	cursor: pointer;
}

.div-block-131:hover {
	opacity: 0.75;
}

.invisible-block {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	display: block;
	height: 100px;
	background-color: transparent;
}

.inner-content-wrap {
	width: 100%;
}

.paragraph-text-2 {
	width: 90%;
	margin-bottom: 25px;
	padding-right: 0px;
	opacity: 1;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: 400;
	letter-spacing: 0.125px;
}

.paragraph-text-2.gray {
	width: 100%;
	max-width: none;
	padding-right: 0px;
	padding-left: 0px;
	color: #333;
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: 0.125px;
}

.paragraph-text-2.gray.left-content {
	max-width: 400px;
	margin-bottom: 10px;
	font-family: Poppins, sans-serif;
	color: #f9f5f2;
	font-size: 0.8rem;
	line-height: 1.2rem;
	font-weight: 500;
	letter-spacing: 0px;
	text-transform: none;
}

.footer-column-three {
	display: block;
	width: 20%;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.right-arrow {
	left: auto;
	top: auto;
	right: 0%;
	bottom: 0%;
	height: 83vh;
	color: #3f3e3e;
}

.right-signoff-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.left {
	padding-right: 10px;
	padding-bottom: 2.5rem;
	padding-left: 10px;
}

.left.left-signoff-column {
	padding-bottom: 0px;
	padding-left: 0px;
}

.left.center-signoff-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-align: center;
}

.grip-logo-text-box {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box.webdevby-gray {
	color: #f9f5f2;
}

.footer-signoff-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.footer-grip-link-2 {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link-2.gray {
	margin-top: 0rem;
	margin-bottom: 0rem;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: no-repeat;
	opacity: 1;
	color: #fff;
}

.footer-signoff-text {
	display: inline-block;
	font-family: Poppins, sans-serif;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 400;
	text-align: left;
}

.home-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: calc(100vh - 180px);
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-signoff-text h1 {
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	display: inline;
	margin: 0px;
	padding: 0px;
	letter-spacing: inherit;
}

.columns-1 {
	margin-bottom: 10px;
}

.footer-links-list {
	margin-top: 0em;
	margin-bottom: 0rem;
	padding-left: 0px;
	border-top: 1px none hsla(0, 0%, 100%, 0.1);
}

.footer-links-list-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	box-shadow: none;
}

.footer-link {
	padding-bottom: 0.25rem;
	border-bottom: 1px solid rgba(158, 171, 183, 0.25);
	color: #bef2ff;
	font-size: 0.875rem;
}

.footer-link:hover {
	color: #bdcf9d;
}

.footer-link.footer-list-item-link {
	display: inline-block;
	margin-bottom: 5px;
	padding-top: 0.25em;
	padding-bottom: 0em;
	border-bottom-color: #b4b7b2;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	font-family: Poppins, sans-serif;
	color: #f9f5f2;
	font-size: 0.8rem;
	line-height: 0.9rem;
	font-weight: 500;
	letter-spacing: 0.5px;
	text-decoration: none;
	text-transform: none;
}

.footer-link.footer-list-item-link:hover {
	border-bottom-color: #2b2c18;
	opacity: 1;
	color: #f9f5f2;
}

.secondary-button {
	display: inline-block;
	margin-right: 20px;
	padding: 0.25rem 0px 0.125rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: none none solid;
	border-width: 1.5px;
	border-color: #f7f7f7 #f7f7f7 #fff;
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 0.8rem;
	line-height: 0.8rem;
	font-weight: 500;
	letter-spacing: 0.5px;
	text-transform: capitalize;
}

.secondary-button:hover {
	border-style: none none solid;
	border-color: #3f3e3e #3f3e3e transparent;
	background-color: transparent;
	color: #fff;
}

.secondary-button.gray {
	margin-left: 20px;
	border-style: none none solid;
	border-color: rgba(4, 4, 4, 0.25) rgba(4, 4, 4, 0.25) #040404;
	color: #040404;
}

.secondary-button.gray:hover {
	border-bottom-color: transparent;
}

.left-nav-list-content-wrap {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: 0rem;
	margin-right: 1rem;
	padding-right: 2rem;
	padding-bottom: 1.5rem;
	border-right: 1px solid rgba(4, 4, 4, 0.05);
}

.div-block-140 {
	position: relative;
	overflow: hidden;
	width: 75%;
	height: 375px;
}

.div-block-140.inside-page {
	width: 100%;
	height: 225px;
}

.inside-page-header-content-wrap-2 {
	max-width: 1200px;
	margin: 0px auto 1.5rem;
	padding: 1.5rem 20px 0.5rem;
	border-top: 1px none rgba(4, 4, 4, 0.05);
	border-bottom: 1px none rgba(4, 4, 4, 0.05);
	background-color: transparent;
}

.main-content-column {
	padding-right: 0px;
	padding-left: 0px;
}

.section-wrap {
	overflow: hidden;
	background-color: #fff;
}

.left-nav-column {
	display: block;
	padding-right: 0px;
	padding-left: 0px;
}

.heading-6 {
	margin-top: 0px;
	margin-bottom: 10px;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-weight: 500;
	letter-spacing: 0.5px;
	text-transform: capitalize;
}

.breadcrumb-list {
	margin: 0rem 0.125rem 1rem;
	padding-left: 0px;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.main-content-section {
	max-width: none;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 1.5rem;
	border: 2px none transparent;
	color: #826338;
	font-size: 0.85em;
	line-height: 1.375em;
	font-weight: 500;
	letter-spacing: 0.5px;
	text-decoration: none;
	text-transform: capitalize;
}

.left-nav-nested-list-link:hover {
	border: 2px none #48bdd7;
	background-color: transparent;
	background-image: none;
	opacity: 1;
	color: #2b2c18;
}

.left-nav-nested-list-link.w--current {
	opacity: 0.7;
	color: #040404;
}

.left-nav-nested-list-link.w--current:hover {
	color: #040404;
}

.left-nav-nested-list-link.grandchild {
	display: inline-block;
	margin-left: 1.15rem;
	padding-right: 1rem;
	padding-bottom: 0.25rem;
	padding-left: 0rem;
	border-bottom: 1px none rgba(4, 4, 4, 0.25);
	-webkit-transition-property: none;
	transition-property: none;
	font-family: Poppins, sans-serif;
	color: #333;
	font-size: 0.8rem;
	line-height: 0.85rem;
	font-weight: 400;
	text-transform: none;
}

.left-nav-nested-list-link.grandchild:hover {
	opacity: 1;
	color: #3f3e3e;
}

.left-nav-nested-list-link.grandchild.current:hover {
	color: #2b2c18;
}

.left-nav-nested-list-link.grandchild.current.w--current {
	padding-right: 0rem;
	padding-bottom: 0rem;
	border-bottom-style: solid;
	border-bottom-color: #b4b7b2;
	opacity: 1;
	color: #826338;
	line-height: 0.95rem;
	font-weight: 700;
}

.left-nav-nested-list-link.grandchild.current.w--current:hover {
	background-image: none;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #2b2c18;
}

.left-nav-nested-list-link.grandchild {
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	color: #333;
}

.left-nav-nested-list-link.grandchild:hover {
	background-image: url('/20181016082423/assets/images/blue-arrow-icon-01.svg');
	background-position: 0% 50%;
	background-size: 12px;
	background-repeat: no-repeat;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #0915f8;
}

.left-nav-nested-list-link.great-grandchild-link {
	padding-top: 0.75rem;
	padding-bottom: 0.25rem;
	font-family: Poppins, sans-serif;
	color: #826338;
	font-size: 0.825em;
	font-weight: 400;
	border-bottom: 0px !important;
}

.left-nav-nested-list-link.great-grandchild-link:hover {
	opacity: 1;
	color: #2b2c18;
}

.left-nav-nested-list-link.grandchild-link {
	display: inline-block;
	margin-left: 1.15rem;
	padding-right: 1rem;
	padding-bottom: 0.25rem;
	padding-left: 0rem;
	border-bottom: 1px none rgba(4, 4, 4, 0.25);
	-webkit-transition-property: none;
	transition-property: none;
	font-family: Poppins, sans-serif;
	color: #826338;
	font-size: 0.8rem;
	line-height: 1.4;
	font-weight: 400;
	text-transform: capitalize;
}

.left-nav-nested-list-link.grandchild-link:hover {
	opacity: 1;
	color: #2b2c18 !important;
}

.link-5 {
	border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
	font-family: Montserrat, sans-serif;
	color: #0915f8;
	font-weight: 400;
	text-decoration: none;
}

.link-5:hover {
	border-bottom-style: none;
	border-bottom-color: #549fc4;
}

.link-5.w--current {
	color: #28313b;
	font-weight: 700;
}

.link-5.breadcrumb {
	display: inline-block;
	padding-bottom: 0rem;
	border-bottom-color: rgba(4, 4, 4, 0.25);
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 0.8rem;
	line-height: 0.9rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: capitalize;
}

.link-5.breadcrumb:hover {
	border-bottom-style: solid;
	border-bottom-color: #fff;
	color: #040404;
}

.left-nav-list-link {
	display: inline-block;
	margin-bottom: 0.125rem;
	padding: 0.75rem 0rem 0rem;
	border-bottom: 1px solid rgba(4, 4, 4, 0.25) !important;
	font-family: Poppins, sans-serif;
	color: #826338 !important;
	font-size: 0.75rem;
	line-height: 0.9rem;
	font-weight: 500 !important;
	letter-spacing: 0.5px;
	text-decoration: none;
	text-transform: capitalize;
}

.left-nav-list-link:hover {
	border-bottom-color: transparent !important;
	background-color: transparent;
	background-image: none;
	opacity: 1;
	color: #2b2c18 !important;
	text-decoration: none;
}

.left-nav-list-link.w--current {
	margin-bottom: 0.125rem;
	border-bottom-style: solid;
	border-bottom-color: #b4b7b2;
	background-color: transparent;
	opacity: 1;
	color: #826338;
	font-size: 0.8rem;
	line-height: 0.8rem;
	font-weight: 700;
	letter-spacing: 0.5px;
	text-transform: capitalize;
}

.left-nav-list-link.w--current:hover {
	color: #2b2c18;
}

.content-container {
	display: block;
	width: 50%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	background-color: #fff;
}

.content-container.threequarterwidth {
	width: 100%;
	max-width: none;
	padding-right: 0rem;
	padding-left: 0rem;
}

.intro-paragraph-2 {
	max-width: none;
	margin-bottom: 1rem;
	padding-bottom: 20px;
	border-bottom: 1px solid rgba(4, 4, 4, 0.05);
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.heading-18 {
	margin-bottom: 5px;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-weight: 500;
	text-transform: capitalize;
}

.left-nav-nested-list-item {
	margin-bottom: 0px;
	box-shadow: none;
}

.div-block-138 {
	height: 100%;
	background-image: url('/20181016082423/assets/images/content-pixie-0z4h9qneDMA-unsplash.jpg');
	background-position: 50% 20%;
	background-size: cover;
	background-repeat: no-repeat;
}

.paragraph-12 {
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.inside-content-beckground {
	position: relative;
	display: block;
	max-width: 1300px;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	padding: 1rem 15px 4rem;
	border-top: 1px none rgba(4, 4, 4, 0.05);
	border-right: 1px none rgba(4, 4, 4, 0.05);
	border-left: 1px none rgba(4, 4, 4, 0.05);
	background-color: #fff;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	padding-left: 0px;
	border-top: 2px none #498fa3;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	padding-left: 0px;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.main-content-wrapper {
	display: block;
	width: 95%;
	max-width: 1200px;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 20px;
	padding-left: 20px;
	border-right: 1px none rgba(4, 4, 4, 0.05);
	border-left: 1px none rgba(4, 4, 4, 0.05);
}

.text-block-21 {
	padding-bottom: 0rem;
	border-bottom: 1px solid #b4b7b2;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 0.8rem;
	line-height: 0.9rem;
	font-weight: 600;
	text-transform: capitalize;
}

.horiz-divider-2 {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(92, 92, 92, 0.15);
	color: #003c43;
}

.left-nav-list-item {
	margin-bottom: 0rem;
	box-shadow: none;
}

.left-nav-list-item-link {
	display: block;
	width: 100%;
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 0.625rem 0.5rem 0.5rem;
	float: left;
	clear: both;
	color: #414141;
	font-size: 1.125rem;
	line-height: 1.125rem;
	font-weight: 600;
	text-decoration: none;
}

.left-nav-list-item-link:hover {
	background-color: #d32329;
	color: #fff;
}

.left-nav-list-item-link.w--current {
	background-color: #d32329;
	background-image: none;
	background-position: 0px 0px;
	color: #fff;
}

.left-nav-list-item-link.w--current:hover {
	background-image: none;
	background-position: 0px 0px;
	color: #fff;
}

.left-nav-list-item-link.left-nav-title-link {
	margin-top: 0px;
	margin-bottom: 2px;
	padding-top: 0.25rem;
	padding-bottom: 1.125rem;
	padding-left: 0rem;
	font-family: Poppins, sans-serif;
	color: #2b2c18;
	font-size: 1.3rem;
	line-height: 1.5rem;
	font-weight: 500;
	letter-spacing: 0.5px;
	text-transform: capitalize;
}

.left-nav-list-item-link.left-nav-title-link:hover {
	background-color: transparent;
	color: #826338;
}

.left-nav-list-item-link.left-nav-title-link.w--current {
	background-color: transparent;
	color: #09b988;
}

.left-nav-list-item-link.left-nav-title-link.w--current:hover {
	background-color: transparent;
	color: #257ecb;
}

.nav-logo-center {
	margin-left: 0px;
	text-align: center;
}

.nav-logo-block {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-top: 0.15rem;
	float: none;
	color: #525252;
	font-size: 1.125rem;
	line-height: 1rem;
	font-weight: 700;
	text-decoration: none;
}

.nav-logo-block:hover {
	color: #555;
}

.nav-logo-image {
	width: 225px;
	float: left;
	clear: none;
}

.nav-header-wrap {
	max-width: none;
	padding-right: 0px;
	padding-left: 0px;
}

.nav-logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.75rem 3rem 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid #fff;
	background-color: transparent;
	opacity: 1;
}

.nav-dropdown-container-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: rgba(0, 0, 0, 0.91);
}

.nav-dropdown-link {
	width: auto;
	margin-right: 32px;
	margin-bottom: 0.75rem;
	margin-left: 32px;
	padding: 1.25rem 5px 0.25rem;
	border: 2px none #000;
	box-shadow: 0 -5px 0 -3px transparent;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.5px;
	text-decoration: none;
	text-transform: capitalize;
}

.nav-dropdown-link:hover {
	border-style: none;
	border-color: #000;
	background-color: transparent;
	box-shadow: 0 -5px 0 -3px #040404;
	color: #040404;
}

.nav-dropdown-link.w--current {
	border-right: 1px solid transparent;
	border-bottom: 1px solid transparent;
	border-left: 1px solid transparent;
	background-color: rgba(39, 127, 139, 0.15);
}

.nav-dropdown-link.link-only {
	padding-top: 1.25rem;
	font-size: 1rem;
	letter-spacing: 0.5px;
	text-transform: capitalize;
}

.nav-dropdown-link.link-only:hover {
	box-shadow: 0 -5px 0 -3px #040404;
}

.nav-links {
	display: block;
	width: auto;
	margin-right: 0px;
	margin-bottom: 0.35rem;
	margin-left: 0px;
	padding: 0.35em 0.75em 0.35em 0.75rem;
	border-style: solid;
	border-width: 2px 2px 1.5px;
	border-color: transparent transparent #040404;
	border-radius: 3px;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 0.8rem;
	font-weight: 300;
	letter-spacing: 0.5px;
	text-transform: capitalize;
}

.nav-links:hover {
	background-color: rgba(63, 62, 62, 0.3);
	color: #fff;
}

.nav-links.w--current {
	color: #fff;
}

.nav-link-5 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 44px;
	max-width: 100px;
	margin-right: 0px;
	padding: 0.8rem 3.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	background-color: transparent;
	color: #525f94;
	font-size: 0.85rem;
	line-height: 1.375em;
	font-weight: 600;
	text-align: center;
}

.nav-link-5:hover {
	background-color: rgba(32, 108, 119, 0.25);
}

.nav-link-5.w--current {
	padding: 0.75rem 1.15rem 0.6rem;
	border-bottom: 2px solid #3aa1af;
	background-color: #f0f5f6;
	box-shadow: inset 1px 0 0 0 #fff, inset -1px 0 0 0 #fff;
	color: #00214b;
	font-size: 0.85rem;
	font-weight: 600;
}

.nav-link-5.w--current:hover {
	border-bottom-color: #3aa1af;
	background-color: #ebf2f3;
	box-shadow: none;
}

.nav-dropdown-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	border-bottom: 4px none transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: hsla(0, 0%, 65.5%, 0.5);
}

.nav-dropdown-wrapper:hover {
	border-bottom: 4px none #799165;
	background-color: transparent;
	color: hsla(0, 0%, 65.5%, 0.8);
}

.nav-dropdown-wrapper.w--open {
	color: #fff;
}

.nav-dropdown-links {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 300px;
	height: auto;
	padding: 0.5rem;
	float: left;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.nav {
	position: relative;
	margin-right: 0.5rem;
	margin-left: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-right: 1px none hsla(0, 0%, 50.2%, 0.25);
}

.nav-dropdown-container {
	display: none;
	width: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.nav-dropdown-container.w--open {
	left: 0px;
	top: 100%;
	display: block;
	width: auto;
	max-width: 1500px;
	padding-left: 0px;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: transparent;
	color: #fff;
}

.nav-menu-wrap {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0rem 0px 0.25rem;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(4, 4, 4, 0.05);
	border-left: 1px none hsla(0, 0%, 50.2%, 0.25);
}

.paragraph-16 {
	font-family: Poppins, sans-serif;
}

.right-sidebar-column {
	padding-left: 0rem;
}

.right-sidebar-column.right-sidebar {
	padding-right: 10px;
	padding-left: 10px;
}

.sidebar-section-title-2 {
	margin-top: 0em;
	margin-bottom: 0.75em;
	font-family: Poppins, sans-serif;
	font-weight: 500;
	letter-spacing: 0.5px;
}

.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.25rem;
	margin-left: 1rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.sidebar-list-item {
	margin-bottom: 0.75rem;
	padding-left: 0px;
	font-family: Poppins, sans-serif;
	color: #040404;
	text-decoration: none;
}

.sidebar-list {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 0em;
}

.link-7 {
	border-bottom: 1px solid #826338;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-weight: 400;
	text-decoration: none;
}

.link-7:hover {
	border-bottom-style: none;
	border-bottom-color: #549fc4;
}

.link-7.w--current {
	color: #28313b;
	font-weight: 700;
}

.breadcrumbs-bar {
	margin-top: 178px;
	border-bottom: 1px none rgba(4, 4, 4, 0.05);
	background-color: #fafafa;
}

.top-column-wrapper {
	margin-bottom: 1rem;
}

.bottom-column-wrapper {
	margin-bottom: 40px;
}

.search-bar-block {
	display: none;
	padding: 1rem 3rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(209, 184, 166, 0.15);
	background-color: #fafafa;
}

.utility-page-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 260px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
}

.utility-page-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.mobile-menu-arrow-2 {
	display: none;
}

.list {
	font-size: 1em;
}

.button-container {
	margin-bottom: 1rem;
}

.block-quote-4 {
	line-height: 1.4;
}

.is--header-link {
	border-bottom: 2px solid #b4b7b2;
	-webkit-transition: border-color 200ms ease, color 200ms ease;
	transition: border-color 200ms ease, color 200ms ease;
	color: #2b2c18;
	text-decoration: none;
}

.is--header-link:hover {
	border-bottom-color: #826338;
	color: #826338;
}

@media screen and (max-width: 991px) {
	.section-slide {
		height: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.home-section {
		height: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.left-block {
		position: static;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		width: 100%;
		height: 60vh;
		padding: 0px 40px 40px;
		border-bottom: 0.5px solid rgba(4, 4, 4, 0.05);
		border-right-style: none;
		background-color: #f7f7f7;
	}

	.heading-titles {
		max-width: none;
	}

	.paragraph {
		max-width: 350px;
	}

	.products-wrapper {
		width: 100%;
		padding-right: 40px;
		padding-left: 40px;
	}

	.product-link-block {
		width: auto;
		margin-right: 0px;
		margin-left: 0px;
		padding-right: 0px;
		padding-left: 20px;
		background-image: none;
	}

	.product-link-block:hover {
		background-size: cover;
	}

	.content-block.call-out {
		width: 100%;
		height: 250px;
	}

	.call-out-image-block-wrapper {
		width: 100%;
		height: auto;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.call-out-image {
		width: 100%;
		height: 400px;
	}

	.footer-sign-off {
		padding-right: 40px;
		padding-left: 40px;
	}

	.social-media-link-block-2 {
		margin-right: 0.75rem;
		-webkit-transition-property: all;
		transition-property: all;
		-webkit-transition-duration: 300ms;
		transition-duration: 300ms;
	}

	.social-media-link-block-2:hover {
		border-radius: 0%;
	}

	.footer-section {
		margin-bottom: 0px;
		padding: 20px 40px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom: 1px none rgba(0, 0, 0, 0.05);
		background-color: #826338;
	}

	.footer-column-one {
		width: 50%;
	}

	.footer-column-one.support {
		width: 30%;
		margin-top: 20px;
		margin-left: -20px;
	}

	.footer-column-two {
		width: 20%;
		margin-bottom: 40px;
		padding-right: 20px;
	}

	.footer {
		margin-left: 0px;
		width: 100%;
	}

	.footer-left-content {
		width: 35%;
	}

	.social-media-wrapper {
		margin-bottom: 0rem;
		margin-left: 0px;
		padding-top: 4px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.nav-bar {
		position: fixed;
		display: block;
		padding-right: 0px;
		padding-left: 0px;
		border-bottom: 1px solid rgba(4, 4, 4, 0.05);
	}

	.slider {
		position: static;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		width: 100%;
		height: 40vh;
		min-height: 280px;
	}

	.slide-nav {
		position: relative;
		top: -40px;
	}

	.left-arrow {
		display: none;
		height: 50vh;
		margin-top: 385.556px;
		margin-bottom: 0px;
	}

	.paragraph-text-2 {
		width: 100%;
	}

	.paragraph-text-2.gray.left-content {
		margin-bottom: 20px;
	}

	.footer-column-three {
		width: 20%;
		margin-bottom: 0px;
	}

	.right-arrow {
		display: none;
		height: 50vh;
		margin-top: 385.556px;
		margin-bottom: 0px;
	}

	.right-signoff-column {
		padding-top: 0rem;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		text-align: center;
	}

	.left.left-signoff-column {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0rem;
		padding-right: 0.5rem;
		padding-bottom: 0.25rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		text-align: center;
	}

	.left.center-signoff-column {
		padding: 0rem 0rem 0.25rem 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-signoff-row {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.footer-signoff-text {
		font-weight: 400;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.div-block-140 {
		width: 65%;
	}

	.inside-page-header-content-wrap-2 {
		margin-bottom: 1rem;
		padding-right: 40px;
		padding-left: 40px;
	}

	.main-content-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.content-container {
		width: 100%;
		padding-left: 0rem;
	}

	.content-container.threequarterwidth {
		width: 100%;
		padding-left: 0rem;
	}

	.inside-content-beckground {
		max-width: 100%;
		padding: 1.5rem 20px 2rem;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.main-content-wrapper {
		width: 100%;
		margin-top: 0px;
	}

	.nav-logo-center {
		margin-top: 0.75rem;
		float: left;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.nav-logo-block {
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
		padding-top: 0rem;
	}

	.nav-logo-image {
		width: 200px;
	}

	.nav-header-wrap {
		display: block;
		max-width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-menu-button {
		display: block;
		margin-left: 0px;
		padding: 1.75rem 0rem 1.75rem 1.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		opacity: 0.75;
		color: #525252;
		font-size: 1.25rem;
		line-height: 1.5rem;
		text-align: center;
		text-transform: uppercase;
	}

	.nav-menu-button.w--open {
		display: block;
		margin-top: 0rem;
		padding: 1.75rem 0rem 1.75rem 1.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: #fff;
		box-shadow: inset 0 -1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #121212;
	}

	.nav-menu-button-icon {
		float: left;
		color: #040404;
		font-size: 2rem;
		line-height: 1.625rem;
	}

	.nav-logo {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding-top: 0.25rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-dropdown-link {
		display: block;
		width: 90%;
		height: 47px;
		margin-right: 0px;
		margin-bottom: 0rem;
		margin-left: 0px;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 1.5rem;
		float: left;
		border-style: none none solid;
		border-bottom-width: 1px;
		border-bottom-color: rgba(4, 4, 4, 0.05);
		color: #3f3e3e;
		font-weight: 500;
		text-align: left;
	}

	.nav-dropdown-link:hover {
		border-style: none none solid;
		border-bottom-color: hsla(0, 0%, 100%, 0.15);
		background-color: transparent;
		box-shadow: inset 4px 0 0 0 #3f3e3e;
		color: #3f3e3e;
	}

	.nav-dropdown-link.link-only {
		width: 100%;
		padding: 0.75rem 0px 0.75rem 1.5rem;
	}

	.nav-dropdown-link.link-only:hover {
		border-left-style: solid;
		border-left-width: 4px;
		box-shadow: none;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1em;
		padding-bottom: 1em;
		float: right;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url('/20181016082423/assets/images/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		color: transparent;
		font-size: 0.875rem;
		line-height: 1.875rem;
		text-align: center;
		cursor: pointer;
	}

	.nav-link-5 {
		display: block;
		width: 100%;
		height: auto;
		min-width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		margin-left: 0px;
		padding: 0.75rem 1rem;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
		background-color: transparent;
		box-shadow: none;
		color: #ddd;
		line-height: 1.375rem;
		font-weight: 600;
		text-align: left;
		text-transform: uppercase;
	}

	.nav-link-5:hover {
		background-color: rgba(131, 142, 153, 0.2);
		box-shadow: inset 4px 0 0 0 #f50000;
		color: #fff;
	}

	.nav-link-5.w--current {
		height: auto;
		margin-left: 0px;
		background-color: rgba(39, 127, 139, 0.15);
		box-shadow: none;
		color: #fff;
	}

	.nav-link-5.w--current:hover {
		background-color: rgba(131, 142, 153, 0.2);
		box-shadow: none;
	}

	.nav-link-5.two-part-mobile {
		width: 80%;
		float: left;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.nav-dropdown-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		border-bottom-style: none;
		box-shadow: inset -4px 0 0 0 transparent;
	}

	.nav-dropdown-wrapper:hover {
		border-bottom-style: none;
		box-shadow: inset -4px 0 0 0 transparent;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.nav {
		position: static;
		width: 100%;
		height: auto;
	}

	.nav-menu-wrap {
		display: block;
		width: 100%;
		max-height: 700px;
		min-width: auto;
		margin-top: 0rem;
		padding-top: 0rem;
		padding-right: 0rem;
		padding-bottom: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		border-top-style: solid;
		background-color: #fff;
		text-align: left;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
		border-top: 3px solid #48bdd7;
	}

	.right-sidebar-column.right-sidebar {
		border-top-style: none;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
	}

	.breadcrumbs-bar {
		margin-top: 109px;
	}

	.search-bar-block {
		display: none;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.mobile-menu-arrow-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 12%;
		height: 47px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid rgba(209, 184, 166, 0.15);
		border-left: 1px solid rgba(209, 184, 166, 0.15);
		color: #000;
	}

	.mobile-menu-arrow-2:hover {
		background-color: transparent;
	}

	.mobile-arrow-2 {
		margin-right: 1.25rem;
		margin-left: 1.25rem;
		color: #5b5b5b;
	}
}

@media screen and (max-width: 767px) {
	.left-block {
		padding: 180px 20px 20px;
	}

	.heading-titles {
		max-width: none;
	}

	.products-wrapper {
		padding-right: 20px;
		padding-left: 20px;
	}

	.product-link-block {
		width: auto;
		height: auto;
		padding-top: 20px;
	}

	.content-block.call-out {
		padding-right: 20px;
		padding-bottom: 20px;
		padding-left: 20px;
		background-position: 0px 0px, 20% 20%;
	}

	.content-block.call-out:hover {
		background-position: 0px 0px, 20% 20%;
	}

	.call-out-image-block-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.call-out-image._2 {
		height: 250px;
		padding-right: 20px;
		padding-bottom: 20px;
		padding-left: 20px;
	}

	.call-out-image._1 {
		height: 250px;
		padding-right: 20px;
		padding-left: 20px;
		background-position: 0px 0px, 50% 35%;
	}

	.footer-sign-off {
		padding-right: 20px;
		padding-left: 20px;
	}

	.footer-section {
		padding: 20px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.footer-column-one.support {
		width: 50%;
		margin-top: 20px;
		margin-left: -10px;
		padding-right: 10px;
	}

	.footer-column-two {
		width: 25%;
		padding-right: 10px;
	}

	.footer-left-content {
		width: 50%;
	}

	.social-media-wrapper {
		margin-bottom: 0rem;
		margin-left: 0px;
		float: none;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.nav-bar {
		padding-right: 10px;
		padding-left: 10px;
	}

	.slide-nav {
		position: relative;
		top: -35px;
		height: auto;
	}

	.paragraph-text-2.gray.left-content {
		margin-bottom: 20px;
		text-align: left;
	}

	.footer-column-three {
		width: 25%;
	}

	.right-signoff-column {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.left {
		padding: 0.75rem 20px 3.5rem;
		border-bottom: 1px solid silver;
	}

	.left.left-signoff-column {
		padding-bottom: 0.25rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-bottom: 1px none #000;
	}

	.left.center-signoff-column {
		padding-top: 0rem;
		padding-bottom: 0.25rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-bottom: 1px none #000;
	}

	.footer-signoff-row {
		text-align: center;
	}

	.footer-signoff-text {
		display: block;
		text-align: left;
	}

	.columns-1 {
		display: block;
	}

	.inside-page-header-content-wrap-2 {
		padding-right: 20px;
		padding-left: 20px;
	}

	.content-container.threequarterwidth {
		padding-right: 0rem;
	}

	.intro-paragraph-2 {
		font-size: 1.125rem;
	}

	.inside-content-beckground {
		padding-right: 20px;
		padding-bottom: 1.5rem;
		padding-left: 20px;
	}

	.main-content-wrapper {
		padding-right: 0px;
		padding-left: 0px;
	}

	.nav-logo-center {
		margin-top: 0rem;
	}

	.nav-logo-block {
		margin-top: 0.75rem;
		padding-left: 0px;
	}

	.nav-logo-image {
		width: 175px;
	}

	.nav-menu-button {
		padding-top: 1.625rem;
		padding-bottom: 1.5rem;
	}

	.nav-menu-button.w--open {
		margin-top: 0px;
		padding-top: 1.625rem;
	}

	.nav-logo {
		padding-top: 0.25rem;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.nav-menu-wrap {
		min-width: 450px;
		padding-top: 0rem;
		padding-bottom: 0rem;
		border-top-style: none;
	}

	.right-sidebar-column {
		margin-top: 1.25rem;
		padding-top: 1.25rem;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.breadcrumbs-bar {
		margin-top: 99px;
	}

	.top-column-wrapper {
		margin-bottom: 0rem;
	}

	.bottom-column-wrapper {
		margin-bottom: 20px;
	}

	.column-product {
		margin-bottom: 1rem;
	}

	.mobile-menu-arrow-2 {
		width: 16%;
	}
}

@media screen and (max-width: 479px) {
	.social-media-link-block-2 {
		margin-right: 0px;
	}

	.left-block {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		padding-top: 200px;
		padding-right: 15px;
		padding-left: 15px;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.heading-titles.white {
		margin-bottom: 1rem;
		text-align: center;
	}

	.inner-content {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.inner-content.category {
		padding-top: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.inner-content.image-call-out {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.paragraph {
		width: 100%;
		max-width: none;
		text-align: center;
	}

	.products-wrapper {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
	}

	.product-link-block {
		padding-left: 20px;
		background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null));
		background-image: linear-gradient(180deg, null, null);
	}

	.product-link-block:hover {
		background-position: 50% 65%;
		background-size: 250px;
	}

	.content-block.call-out {
		width: 100%;
		padding-right: 15px;
		padding-bottom: 20px;
		padding-left: 15px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-position: 0px 0px, 40% 20%;
		background-size: auto, cover;
	}

	.content-block.call-out:hover {
		background-position: 0px 0px, 40% 20%;
	}

	.main-button {
		margin-right: 10px;
		padding-right: 8px;
		padding-left: 8px;
	}

	.main-button._2 {
		padding-right: 8px;
		padding-left: 8px;
	}

	.call-out-image-block-wrapper {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.call-out-image._2 {
		height: 300px;
		padding-right: 15px;
		padding-bottom: 20px;
		padding-left: 15px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.call-out-image._1 {
		height: 300px;
		padding-right: 15px;
		padding-bottom: 20px;
		padding-left: 15px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-sign-off {
		display: block;
		padding-right: 30px;
		padding-left: 30px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-section {
		padding: 40px 15px 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-column-one {
		width: 100%;
		margin-bottom: 40px;
	}

	.footer-column-one.support {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-bottom: 15px;
		margin-left: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.footer-column-two {
		width: 50%;
		margin-bottom: 20px;
	}

	.footer-left-content {
		width: 100%;
		margin-bottom: 20px;
		padding-right: 0px;
	}

	.social-media-wrapper {
		margin-left: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-column-heading {
		text-align: center;
	}

	.link-block-2 {
		margin-bottom: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-bar {
		display: block;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.slider {
		height: 200px;
	}

	.slide {
		height: 100%;
	}

	.slide-nav {
		position: relative;
		top: -25px;
		display: block;
		height: auto;
		margin-top: 0px;
		padding-top: 0px;
	}

	.left-arrow {
		display: none;
		overflow: hidden;
		height: 30vh;
		margin-top: 380px;
	}

	.form-block-2 {
		width: 60%;
	}

	.paragraph-text-2 {
		text-align: center;
	}

	.paragraph-text-2.gray {
		text-align: center;
	}

	.paragraph-text-2.gray.left-content {
		margin-bottom: 20px;
		text-align: center;
		max-width: none;
	}

	.footer-column-three {
		width: 50%;
		margin-bottom: 40px;
	}

	.right-arrow {
		display: none;
		height: 30vh;
		margin-top: 380px;
	}

	.right-signoff-column {
		padding-top: 0.25rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		text-align: left;
	}

	.left {
		padding-right: 10px;
		padding-left: 10px;
		border-bottom-width: 1px;
		border-bottom-color: silver;
	}

	.left.left-signoff-column {
		padding-right: 0px;
		padding-bottom: 0.25rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		border-bottom: 1px none #000;
		text-align: left;
	}

	.left.center-signoff-column {
		padding-top: 0.25rem;
		padding-bottom: 0.75rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px none #000;
		text-align: left;
	}

	.footer-signoff-row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-signoff-text {
		padding-bottom: 0.25rem;
		text-align: left;
	}

	.columns-1 {
		margin-bottom: 0px;
	}

	.footer-links-list-item {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		text-align: left;
	}

	.footer-link.footer-list-item-link {
		padding-bottom: 0em;
		font-size: 0.825rem;
		text-align: left;
	}

	.secondary-button {
		margin-right: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.div-block-140 {
		width: 100%;
		height: auto;
	}

	.inside-page-header-content-wrap-2 {
		text-align: center;
	}

	.link-5.breadcrumb {
		font-size: 0.8rem;
	}

	.content-container.threequarterwidth {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.intro-paragraph-2 {
		line-height: 1.5rem;
	}

	.div-block-138 {
		background-position: 20% 50%;
	}

	.paragraph-12 {
		font-size: 0.9rem;
	}

	.inside-content-beckground {
		padding-right: 20px;
		padding-left: 20px;
	}

	.text-block-21 {
		font-size: 0.8rem;
	}

	.nav-logo-center {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0.5rem;
		float: none;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-logo-block {
		width: auto;
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
	}

	.nav-logo-image {
		width: 125px;
		height: auto;
		float: none;
	}

	.nav-header-wrap {
		display: block;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-menu-button {
		display: block;
		width: auto;
		margin-left: 0px;
		padding: 0rem;
		float: none;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-menu-button.w--open {
		padding: 0rem;
	}

	.nav-menu-button-icon {
		display: inline-block;
	}

	.nav-logo {
		width: auto;
		margin-right: 0px;
		padding: 0.25rem 0.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-dropdown-link {
		width: 84%;
		background-position: 95% 50%;
	}

	.nav-dropdown-link.link-only.top {
		border-top: 1px solid rgba(209, 184, 166, 0.15);
	}

	.two-part-button-toggle {
		width: 25%;
		line-height: 1.625rem;
	}

	.nav-link-5.two-part-mobile {
		width: 75%;
	}

	.nav-menu-wrap {
		width: 100vw;
		max-height: 580px;
		min-width: auto;
	}

	.right-sidebar-column {
		margin-top: 1rem;
		padding-top: 1rem;
	}

	.div-block-144 {
		width: 100%;
	}

	.div-block-145 {
		width: 100%;
	}

	.div-block-146 {
		width: 100%;
	}

	.div-block-147 {
		width: 100%;
	}

	.breadcrumbs-bar {
		margin-top: 60px;
	}

	.column-product {
		padding-right: 0px;
		padding-left: 0px;
	}

	.search-bar-block {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.mobile-menu-arrow-2 {
		position: relative;
		width: 20%;
	}
}

/****************** Webflow Styles ***********************/
/****************************************************/

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.nav-bar {
	transition: all 350ms ease;
}

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu {
	height: 61px;
}

.accesible-navigation-menu ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.accesible-navigation-menu li.nav-item-li {
	position: relative;
	margin: 0px;
	padding: 0px;
}

.accesible-navigation-menu li.nav-item-li a {
	display: inline-block;
}

.accesible-navigation-menu li.nav-item-li a.nav-dropdown-link.active {
	box-shadow: 0 -5px 0 -3px #040404;
	background-color: transparent;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: 0;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
}

.accesible-navigation-menu li.nav-item-li:last-child .container-wrapper {
	right: 0;
	left: auto;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper ul {
	display: none;
	padding: 0.5rem;
	background-color: rgba(0, 0, 0, 0.91);
	margin: 0px;
	list-style-type: none;
	min-width: 300px;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper ul a {
	padding: 8px 20px;
	margin-bottom: 0.35rem;
	margin-left: 0px;
	padding: 0.35em 0.75em 0.35em 0.75rem;
	text-decoration: none;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 0.8rem;
	font-weight: 300;
	letter-spacing: 0.5px;
	text-transform: capitalize;
	display: inline-block;
	width: 100%;
	background-color: transparent;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper ul a:hover,
.accesible-navigation-menu li.nav-item-li .container-wrapper ul a.w--current {
	background-color: rgba(63, 62, 62, 0.3);
	color: #fff;
	display: inline-block;
}

.accesible-navigation-menu li.nav-item-li.open .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.nav-item-li.open .container-wrapper ul {
	display: inline-block;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mobile-menu-button {
		display: none;
	}

	.mm-page.mm-slideout {
		width: 100% !important;
		display: inherit !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #fff;
}

.mobile-navigation-menu li a.nav-dropdown-link,
.mm-navbar__title > span {
	text-align: left;
	font-weight: 500;
	color: #3f3e3e;
	padding: 0.75rem 0px 0.75rem 1.5rem;
	transition: all 300ms ease;
}

.mm-navbar__title > span {
	border-left: 0px;
	padding-left: 0px;
	padding: 0px;
}

.mobile-navigation-menu li.mm-listitem.active a.nav-dropdown-link,
.mobile-navigation-menu li.mm-listitem:hover a.nav-dropdown-link {
	border-left-style: solid;
	border-left-width: 4px;
	box-shadow: none;
}

.mm-listitem_selected > .mm-listitem__text {
	background-color: transparent;
}

.mm-navbar > * {
	justify-content: flex-start;
}

.mm-listitem__btn {
	border-color: rgba(4, 4, 4, 0.05);
}

.mm-listitem:after {
	border-color: rgba(4, 4, 4, 0.05);
	left: 0;
}

.mm-navbar {
	min-height: 55px;
	border-color: rgba(4, 4, 4, 0.05);
}

.mm-btn_next:after,
.mm-btn_prev:before {
	border-color: #5b5b5b;
	width: 8px;
	height: 8px;
}

/********************* Homepage *********************/
/*****************************************************/
.home-text p,
.home-text li,
.home-text a,
.home-text a:hover,
.home-text td,
.home-text span {
	font-size: inherit;
	line-height: inherit;
	color: inherit !important;
	font-family: inherit;
	text-decoration: none;
	font-weight: inherit;
}

.home-text h1,
.home-text h2,
.home-text h3,
.home-text h4,
.home-text h5,
.home-text h6 {
	color: inherit !important;
}

.home-text h1,
.home-text h2,
.home-text h3,
.home-text h4,
.home-text h5,
.home-text h6 {
	color: inherit !important;
}

.home-text li {
	text-align: left;
}

.slide-caption .primary:hover {
	color: #fff !important;
}

.home-text p:last-child {
	margin-bottom: 0px;
}

/*********************** Slideshow ********************/
/*****************************************************/
.slide-caption {
	position: absolute;
	padding: 20px 20px 50px 20px;
	bottom: 0;
	width: 100%;
	background-color: #ececec;
}

@media screen and (min-width: 992px) {
	.home-section {
		margin-top: 180px;
	}

	.left-block {
		margin-top: -180px;
	}
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube.com"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumb-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumb-list li a.link-5.breadcrumb {
	display: inline-block;
	line-height: 0.9rem;
}

.breadcrumb-list li:last-child a,
.breadcrumb-list li:last-child a:hover {
	padding-bottom: 0rem;
	border-bottom: 1px solid #b4b7b2;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 0.8rem;
	font-weight: 600;
	text-transform: capitalize;
}

h2.left-nav-list-item-link a,
h2.left-nav-list-item-link a:hover {
	font-family: inherit;
	line-height: inherit;
	color: inherit !important;
	font-weight: inherit;
	text-decoration: none;
	border-bottom: 0px !important;
	font-size: inherit;
}

h2.left-nav-list-item-link a:hover {
	color: #826338 !important;
}

.inside-row {
	min-height: 500px;
}

.left-nav-list-link.active {
	margin-bottom: 0.125rem;
	border-bottom-style: solid;
	border-bottom-color: #b4b7b2 !important;
	background-color: transparent;
	opacity: 1;
	color: #826338 !important;
	font-size: 0.8rem;
	line-height: 0.8rem;
	font-weight: 700 !important;
	letter-spacing: 0.5px;
	text-transform: capitalize;
}

.left-nav-list-link.active:hover {
	color: #2b2c18 !important;
}

.left-nav-nested-list-link.grandchild-link.active {
	padding-right: 0rem;
	padding-bottom: 0rem;
	border-bottom-style: solid;
	border-bottom-color: #b4b7b2;
	opacity: 1;
	line-height: 0.95rem;
	font-weight: 600;
	background-image: none;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #826338;
}

.left-nav-nested-list-link.great-grandchild-link.active {
	opacity: 1;
	color: #040404;
}

ul li.left-nav-list-item {
	margin-top: 6px;
	display: inline-block;
	width: 100%;
}

ul li.left-nav-list-item .left-nav-list-link {
	display: inline;
}

.left-nav-nested-list-item {
	margin-left: 1.15rem;
	margin-top: 4px;
}

.left-nav-nested-list-link.grandchild-link {
	display: inline;
	margin-left: 0px;
}

.left-nav-nested-list-link.great-grandchild-link {
	margin-left: 0px;
	display: inline;
}

li.left-nav-nested-list-item li.left-nav-nested-list-item {
	margin-top: 7px;
	margin-left: 1.4rem;
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-sidebar-column .box {
	margin-bottom: 2.25rem;
	margin-left: 1rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.right-sidebar-column .box p,
.right-sidebar-column .box li,
.right-sidebar-column .box td,
.right-sidebar-column .box span {
	font-size: 0.875rem;
	line-height: 1.35rem;
	font-family: Poppins, sans-serif;
	color: #333;
}

.right-sidebar-column .box a {
	border-bottom: 1px solid #826338;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-weight: 400;
	text-decoration: none;
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.right-sidebar-column .box a:hover {
	border-bottom: 1px solid transparent;
	color: #040404;
}

.right-sidebar-column .box p {
	margin-top: 0;
	font-family: Poppins, sans-serif;
	margin-bottom: 10px;
}

.right-sidebar-column .box h4,
.right-sidebar-column .box h3 {
	margin-top: 0em;
	margin-bottom: 0.75em;
	font-family: Poppins, sans-serif;
	font-weight: 500;
	letter-spacing: 0.5px;
	font-size: 1rem;
}

.right-sidebar-column .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar-column .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar-column .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar-column .box.tools td:first-child img {
	margin-right: 5px;
}

.right-sidebar-column .box td img {
	max-width: none;
}

@media (max-width: 991px) {
	.right-sidebar-column .box {
		margin-left: 0px;
	}

	.right-sidebar-column.right-sidebar {
		padding-left: 0px;
		padding-right: 0px;
	}
}

/********************* Footer **********************/
/*****************************************************/
#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
}

.footer-link.desktop {
	display: inline-block;
	border-bottom: 0px;
}

.footer-link.mobile {
	display: none;
}

.footer-link.active {
	border-bottom: 0px;
}

@media (max-width: 991px) {
	.footer-link.desktop {
		display: none;
	}

	.footer-link.mobile {
		display: inline-block;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
h1 {
	margin-top: 0px;
	margin-bottom: 10px;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-weight: 500;
	letter-spacing: 0.5px;
	text-transform: capitalize;
}

h2 {
	margin-bottom: 12px;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-weight: 500;
	text-transform: capitalize;
}

h3 {
	margin-bottom: 12px;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-weight: 500;
	text-transform: capitalize;
}

h4 {
	margin-bottom: 5px;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-weight: 500;
	text-transform: capitalize;
}

h5 {
	margin-bottom: 5px;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-weight: 500;
	text-transform: capitalize;
}

h6 {
	margin-bottom: 5px;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-weight: 500;
	text-transform: capitalize;
}

h1 a {
	border-bottom: 2px solid #b4b7b2;
	transition: border-color 200ms ease, color 200ms ease;
	color: #2b2c18;
	text-decoration: none;
	line-height: 55px;
}

h2 a {
	border-bottom: 2px solid #b4b7b2;
	transition: border-color 200ms ease, color 200ms ease;
	color: #2b2c18;
	text-decoration: none;
}

h3 a {
	border-bottom: 2px solid #b4b7b2;
	transition: border-color 200ms ease, color 200ms ease;
	color: #2b2c18;
	text-decoration: none;
}

h4 a {
	border-bottom: 2px solid #b4b7b2;
	transition: border-color 200ms ease, color 200ms ease;
	color: #2b2c18;
	text-decoration: none;
}

h5 a {
	border-bottom: 2px solid #b4b7b2;
	transition: border-color 200ms ease, color 200ms ease;
	color: #2b2c18;
	text-decoration: none;
}

h6 a {
	border-bottom: 2px solid #b4b7b2;
	transition: border-color 200ms ease, color 200ms ease;
	color: #2b2c18;
	text-decoration: none;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	border-bottom-color: #826338;
	color: #826338;
}

body {
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

p {
	margin-bottom: 1rem;
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

/* buttons */
.inside-page-container .button,
.inside-page-container a.primary,
.homeboxes a.primary,
.primary,
.inside-page-container button[type="submit"],
.inside-page-container .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	padding: 9px 15px;
	display: inline-block;
	padding-right: 22px;
	padding-left: 22px;
	border-style: solid;
	border-width: 1px;
	border-color: #040404 !important;
	border-radius: 3px;
	background-color: #fff !important;
	font-family: Poppins, sans-serif;
	color: #040404 !important;
	font-size: 0.8rem;
	letter-spacing: 0.5px;
	text-transform: capitalize;
	transition: all 400ms ease;
	line-height: 1rem;
	font-weight: 500;
	text-decoration: none !important;
	cursor: pointer;
}

.primary:hover,
.inside-page-container a.primary:hover .inside-page-container button[type="submit"]:hover,
.inside-page-container .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.homeboxes a.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	background-color: #040404 !important;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #fff !important;
}

.secondary,
.inside-page-container a.secondary,
.homeboxes a.secondary,
.inside-page-container button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-page-container .cms_form_button.secondary,
.inside-page-container .button.secondary {
	display: inline-block;
	padding: 0.25rem 0px 0.125rem;
	border-style: none none solid;
	border-width: 1.5px;
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	font-family: Poppins, sans-serif;
	font-size: 0.8rem;
	line-height: 0.8rem;
	font-weight: 500;
	letter-spacing: 0.5px;
	text-transform: capitalize;
	border-style: none none solid;
	border-color: rgba(4, 4, 4, 0.25) rgba(4, 4, 4, 0.25) #040404 !important;
	color: #040404 !important;
	text-decoration: none !important;
}

.secondary:hover,
.inside-page-container a.secondary:hover,
.inside-page-container button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-page-container .cms_form_button.secondary:hover,
.inside-page-container .button:hover,
.homeboxes a.secondary:hover {
	border-bottom-color: transparent !important;
}

.tertiary,
.inside-page-container button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-page-container a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	padding: 0.25rem 0px 0.125rem;
	border-style: none none solid;
	border-width: 1.5px;
	border-radius: 0px;
	background-color: transparent;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	font-family: Poppins, sans-serif;
	font-size: 0.8rem;
	line-height: 0.8rem;
	font-weight: 500;
	letter-spacing: 0.5px;
	text-transform: capitalize;
	border-style: none none solid;
	border-color: rgba(4, 4, 4, 0.25) rgba(4, 4, 4, 0.25) #040404 !important;
	color: #040404 !important;
	text-decoration: none !important;
}

.tertiary:hover,
.inside-page-container a.tertiary:hover,
.inside-page-container button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	border-bottom-color: transparent !important;
}

/* messages */
#message.success,
#polls .session_message.success {
	background-color: #98e4a3;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

#message.error,
#polls .session_message.error {
	background-color: #f3baba;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	background-color: #826338;
	color: #f9f5f2;
	letter-spacing: 0.05rem;
	padding: 0.5rem;
}

table.styled th {
	text-align: left;
	background-color: #f9f5f2;
	color: #826338;
	padding: 0.5rem;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	font-family: Poppins, sans-serif;
	color: #040404;
	font-size: 1rem;
	line-height: 1.5;
	padding: 0.5rem;
}

table.styled.striped tr:nth-child(even) {
	background: rgba(130, 99, 56, 0.35);
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"],
#submissionforms_module.cms_form .sigPad input {
	max-width: 100%;
	padding: 8px 12px;
	margin-bottom: 5px;
	margin-top: 5px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #666;
}

#submissionforms_module.cms_form .sigWrapper.current {
	border: 1px solid #666;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	margin: 0 0 10px 0;
	padding: 10px 20px;
	border-left: 5px solid #e2e2e2;
	font-size: 18px;
	line-height: 1.4;
	font-family: Poppins, sans-serif;
	color: #040404;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #333333;
	font-style: italic;
}

#pagination span {
	color: #333 !important;
}

/*********************** Modules ********************/
/*****************************************************/
/* photo albums */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow .slide-caption {
	background-color: #666;
	font-family: inherit;
	color: #ffffff;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* submission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/******************* Content Boxes *******************/
/****************************************************/
/* sumbisison forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}


